<template>
  <div class="edit">
    <div class="my-list">
      <div class="my-list-title">
        <img class="icon-imgs" src="../../assets/image/show/share1.png" />
        <span>信息填写</span>
      </div>
      <div class="edit-form">
        <div class="form-items">
          <div class="label-title">农场名称</div>
          <el-input
            v-model.trim="farmShow.title"
            maxlength="18"
            placeholder="请输入农场名称"
            @change="getInputValue"
          ></el-input>
        </div>
        <div class="form-items">
          <div class="label-title">农场口号</div>
          <el-input
            v-model.trim="farmShow.slogan"
            maxlength="18"
            placeholder="请输入农场口号"
            @change="getInputValue"
          ></el-input>
        </div>
        <div class="form-items">
          <div class="label-title">农场电话</div>
          <el-input
            v-model.trim="farmShow.phone"
            placeholder="请输入农场电话"
            @change="getInputValue"
            maxlength="11"
          ></el-input>
        </div>
        <div class="form-items">
          <div class="label-title">农场简介</div>
          <el-input
            v-model.trim="farmShow.desc"
            type="textarea"
            autocomplete="off"
            placeholder="请输入农场简介"
            maxlength="100"
            show-word-limit
          >
          </el-input>
        </div>
        <div class="form-items">
          <div class="label-title">农场产品</div>
          <div class="products">
            <div
              :class="[
                'product-items',
                pids.indexOf(t.id) != -1 ? 'active' : '',
              ]"
              v-for="t in cropList"
              :key="t.id"
              @click="changeProduct(t.id)"
            >
              <img
                class="product-img"
                :src="
                  t.photo
                    ? uploadImgs + t.photo
                    : require('../../assets/image/crops/item1.jpg')
                "
                alt=""
              />
              <span class="product-name">{{ t.title }}</span>
              <img
                v-show="pids.indexOf(t.id) != -1"
                class="border"
                src="../../assets/image/show/change.png"
                alt=""
              />
            </div>
          </div>
        </div>

        <div class="dialog-footer">
          <el-button class="no-button" @click="noAdd">取 消</el-button>
          <el-button class="ok-button" type="primary" @click="okAdd"
            >确 定</el-button
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getProductListApi,
  postAddShowApi,
  getFarmShowInfoApi,
  postEditShowApi,
} from "@/request/api.js";
import { mapState, mapMutations } from "vuex";

export default {
  data() {
    return {
      farmShow: {
        title: "",
        slogan: "",
        desc: "",
        phone: "",
        pids: [],
      },
      cropList: [],
      pids: [],
      current_page: 1,
      count: 0,
      manor_show_id: undefined,
      templetId: undefined,
    };
  },
  watch: {
    farmShow: {
      handler(newValue) {
        this.SET_FARM_SHOW({ farmShow: newValue });
      },
      deep: true,
    },
  },
  computed: {
    ...mapState({
      farmID: (state) => state.user.farmID,
      uploadImgs: (state) => state.app.uploadImgs,
    }),
  },
  created() {
    this.manor_show_id = this.$route.query.id;
    this.templetId = this.$route.query.templetId;
    if (this.manor_show_id) {
      this.getFarmShowInfoApi(this.manor_show_id);
    }
    this.getProductListApi();
  },
  methods: {
    ...mapMutations("user", ["SET_FARM_SHOW"]),
    async getFarmShowInfoApi(manor_show_id) {
      const { code, results } = await getFarmShowInfoApi({
        manor_id: this.farmID,
        manor_show_id,
      });
      if (code != 0) return;
      this.pids = JSON.parse(results.show_info.pids);
      results.show_info.pids = JSON.parse(results.show_info.pids);
      this.farmShow = results.show_info;
      this.farmShow.title = results.show_info.show_title;
    },
    noAdd() {
      this.$router.push({
        name: "showList",
      });
      this.$emit("handleReturns");

      this.pids = [];
      this.farmShow = {
        title: "",
        slogan: "",
        desc: "",
        phone: "",
        pids: [],
      };
      this.SET_FARM_SHOW({ farmShow: {} });
    },

    okAdd() {
      if (this.manor_show_id) {
        this.postEditShowApi();
      } else {
        this.postAddShowApi();
      }
    },
    async postEditShowApi() {
      if (typeof this.farmShow.pids == "object") {
        this.farmShow.pids = JSON.stringify(this.farmShow.pids);
      }
      const { code } = await postEditShowApi({
        manor_id: this.farmID,
        templet_id: this.templetId,
        manor_show_id: this.manor_show_id,
        ...this.farmShow,
      });
      if (code != 0) return;
      this.$notify({
        title: "提示",
        message: "设置成功！",
        type: "success",
      });
      this.$router.push({
        name: "showList",
      });
      this.$emit("handleReturns");
      this.pids = [];
      this.farmShow = {
        title: "",
        slogan: "",
        desc: "",
        phone: "",
        pids: [],
      };
      this.SET_FARM_SHOW({ farmShow: {} });
    },
    async postAddShowApi() {
      if (!this.farmShow.pids.length) {
        this.farmShow.pids = JSON.stringify(this.farmShow.pids);
      }
      const { code } = await postAddShowApi({
        manor_id: this.farmID,
        templet_id: this.templetId,
        ...this.farmShow,
      });
      if (code != 0) return;
      this.$notify({
        title: "提示",
        message: "设置成功！",
        type: "success",
      });
      this.$router.push({
        name: "showList",
      });
      this.$emit("handleReturns");
      this.pids = [];
      this.farmShow = {
        title: "",
        slogan: "",
        desc: "",
        phone: "",
        pids: [],
      };
      this.SET_FARM_SHOW({ farmShow: {} });
    },
    changeProduct(id) {
      if (this.pids.indexOf(id) != -1) {
        this.pids.splice(this.pids.indexOf(id), 1);
      } else {
        this.pids.push(id);
      }
      this.farmShow.pids = JSON.stringify(this.pids);
    },
    async getProductListApi() {
      const { code, results } = await getProductListApi({
        manor_id: this.farmID,
        pn: this.current_page,
      });
      if (code === 0) {
        this.count = results.count;
        this.current_page = results.pn;
        this.cropList = results.data;
      }
    },
    getInputValue() {
      this.$emit("getEditShow", this.list);
    },
  },
};
</script>

<style lang="less" scoped>
.edit {
  .my-list {
    width: 1320px;
    margin-bottom: 30px;
    .my-list-title {
      width: 100%;
      height: 56px;
      background: #0e2f53;
      display: flex;
      align-items: center;
      padding: 0 30px;
      .icon-imgs {
        width: 28px;
      }
      span {
        font-size: 22px;
        color: #fff;
        margin-left: 12px;
      }
    }
    .edit-form {
      background-color: #081c30;
      padding: 52px 42px;
      /deep/.form-items {
        display: flex;
        margin-bottom: 37px;
        .label-title {
          height: 38px;
          color: #fff;
          font-size: 14px;
          margin-right: 23px;
          line-height: 38px;
        }
        .el-textarea {
          width: auto;
          .el-textarea__inner {
            background-color: #081c30;
            border: 1px solid #385576;
            color: #57708d;
            width: 446px;
            height: 120px;
            resize: none;
            margin: 0;
          }
          .el-input__count {
            background-color: #081c30;
            color: #fff;
          }
        }
        .el-input {
          width: 446px;
          height: 38px;
          .el-input__inner {
            width: 100%;
            height: 38px;
            background: #081c30;
            border: 1px solid #385576;
            border-radius: 3px;
          }
        }

        .products {
          width: 93%;
          height: 260px;
          display: flex;
          flex-wrap: wrap;
          overflow-y: auto;
          &::-webkit-scrollbar {
            width: 2px;
          }
          &::-webkit-scrollbar-thumb {
            border-radius: 10px;
            background-color: #3e90e5;
          }
          &::-webkit-scrollbar-track {
            background-color: #0e2138;
          }
          .product-items {
            min-width: 140px;
            height: 180px;
            display: flex;
            flex-direction: column;
            align-items: center;
            margin: 0 10px 10px 0;
            background-color: #fff;
            padding: 10px 10px 0;
            border-radius: 10px;
            border: 4px solid #0e2f53;
            position: relative;
            cursor: pointer;
            .border {
              width: 48px;
              position: absolute;
              top: 0;
              right: -4px;
            }
            .product-img {
              width: 120px;
              height: 120px;
              border-radius: 50%;
              margin-bottom: 8px;
            }
            .product-name {
              color: #333;
              font-size: 14px;
            }
            &.active {
              border-color: #3e90e5;
            }
          }
        }
      }
      /deep/.dialog-footer {
        .el-button.ok-button {
          width: 120px;
        }
        .el-button.no-button {
          width: 120px;
        }
      }
    }
  }
}
</style>
